const getMetaRobotsTeamsales = ({ isHomePage }: { isHomePage: boolean }) =>
  isHomePage ? "index" : "noindex";

export const getMetaRobots = ({
  isTeamsales,
  isHomePage = false,
}: {
  isTeamsales: boolean;
  isHomePage?: boolean;
}) => (isTeamsales ? getMetaRobotsTeamsales({ isHomePage }) : "index, follow");
